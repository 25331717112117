import IntlMessages from '@crema/utility/IntlMessages'
import { MenuItem } from '@material-ui/core'
import FormikTextField from 'components/Fields/FormikTextField'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { donationListThunk, selectCurrentTempleDonation } from 'store/reducers/temple/donationSlice'

interface DonationDonationSelectProp {
    categoryId: string | number,
    className: string,
    // handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void,
}
const DonationDonationSelect: React.FC<DonationDonationSelectProp> = ({ className, categoryId }) => {
    const donations = useAppSelector(selectCurrentTempleDonation)
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(donationListThunk({}))
    }, [dispatch])

    return (
        <FormikTextField
            select
            name='donation'
            className={className}
            size="small"
            label={<IntlMessages id='sidebar.donation' />}
            margin="none"
            // onChange={handleChange}
        >
            <MenuItem value="">None</MenuItem>
            {categoryId ? donations.filter(donation => donation.category.id === categoryId).map(donation => (
                <MenuItem key={donation.id} value={donation.id}>
                    {donation.name}
                </MenuItem>
            )) : donations.map(donation => (
                <MenuItem key={donation.id} value={donation.id}>
                    {donation.name}
                </MenuItem>
            ))}
        </FormikTextField>
    )
}

export default DonationDonationSelect
import { UserRoleType } from "store/reducers/auth/authSlice"

export const baseUrl = process.env.REACT_APP_API_SERVER
// export const baseUrl = "http://127.0.0.1:8000"

export const mapsApiKey = 'AIzaSyAZ8-suz4wpWtAty_Hste9dYr6d_BUCm3M'

// local storage keys
export const tokenKey = "jwt"
export const deviceIdKey = "deviceId"
export const currentTempleKey = "currentTemple"
export const userRoleKey = 'userRole'
export const permissionsKey = 'permissionTemplate'

export const initialUrl = '/dashboard'
export const initialUrls : Record<UserRoleType, string> = {
    user: '/dashboard',
    staff: '/staff'
}
export const redirectUrls = ['/', '/login', '/user-switch']

export const weekDays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'] as const;

export const defaultDateFormat = 'dd/MM/yyyy'
export const defaultTimeFormat = 'hh:mm a'
export const defaultDateTimeFormat = 'do MMMM, yyyy hh:mm a'

export const apiDateFormat = 'yyyy-MM-dd'
export const apiTimeFormat = 'HH:mm:ss'


export const maxFileSize = 5242880
export const allowedImgTypes = ['image/jpeg', 'image/png']
export const allowedFileTypes = [...allowedImgTypes, 'application/pdf']

export const phoneNoRegex = /^\+?[\d\s]{8,20}$/
import { Loader } from '@crema'
import Error404 from 'components/ErrorPage/Error404'
import GoBack from 'components/GoBack'
import React, { useEffect } from 'react'
import { RouteConfigComponentProps } from 'react-router-config'
import { apiStatus } from 'shared/constants/AppEnum'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { receiptRetrieveThunk, selectReceiptById, selectReceiptStatus } from 'store/reducers/temple/receiptSlice'
import BookingReceipt from './BookingReceipt'

interface RouteParams {
    id: string | undefined
}

const ReceiptLayout: React.FC<RouteConfigComponentProps<RouteParams>> = props => {
    const { id } = props.match.params
    const dispatch = useAppDispatch();
    const receipt = useAppSelector(state => selectReceiptById(state, id ?? ''))
    const receiptStatus = useAppSelector(selectReceiptStatus)
    useEffect(() => {
        if (id)
            dispatch(receiptRetrieveThunk({ id: id }))
    }, [dispatch, id])

    return (
        receiptStatus.status === apiStatus.LOADING ?
            <Loader /> :
            receipt ?
                (
                    <GoBack {...props}>
                        <BookingReceipt receipt={receipt} />
                    </GoBack>
                ) : <Error404 />
    )
}

export default ReceiptLayout
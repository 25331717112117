import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import { apiStatus } from 'shared/constants/AppEnum'
import { offeringCategoryAPI } from 'api/temple'
import { AppState } from 'store/reduxStore'
import { IOfferingCategoryResponse, TempleIdType } from 'types/api/temple'
import { IApiState } from 'store/types'
import { IOfferingCategory } from 'types/store/temple'
import { selectCurrentTempleId } from './templeSlice'

const offeringCategoryAdapter = createEntityAdapter<IOfferingCategoryResponse>({
    sortComparer: (a, b) => a.name.localeCompare(b.name),
    // selectId: (obj) => obj.url
})

type InitialStateType = {
    api: IApiState
}

const addOnIntitalState: InitialStateType = {
    api: {
        status: apiStatus.IDLE,
        error: null
    }
}

export const offeringCategoryListThunk = createAsyncThunk(
    'offeringCategory/list',
    async(payload: TempleIdType, {rejectWithValue, getState}) => {
        let templeId = payload.templeId ?? selectCurrentTempleId(getState() as AppState)
        try{
            const response = await offeringCategoryAPI.list(templeId)
            return response.data.map(offeringCategory => {
                offeringCategory.templeId = templeId
                return offeringCategory
            })
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const offeringCategoryCreateThunk = createAsyncThunk(
    'offeringCategory/create',
    async(payload: IOfferingCategory & TempleIdType, {rejectWithValue, getState}) => {
        let templeId = payload.templeId ?? selectCurrentTempleId(getState() as AppState)
        try{
            const response = await offeringCategoryAPI.post(payload, templeId)
            response.data.templeId = templeId
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const offeringCategoryUpdateThunk = createAsyncThunk(
    'offeringCategory/update',
    async(payload: PayloadWithUrl<Partial<IOfferingCategory>>, {rejectWithValue}) => {
        try{
            const response = await offeringCategoryAPI.update(payload.url, payload.data)
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const offeringCategoryRetrieveThunk = createAsyncThunk(
    'offeringCategory/retrieve',
    async(payload: IdOrUrlType & TempleIdType, {rejectWithValue, getState}) => {
        let templeId = payload.templeId ?? selectCurrentTempleId(getState() as AppState)
        try{
            let params
            if ('id' in payload && payload.id)
                params = { itemId: payload.id }
            else if ('url' in payload)
                params = { url: payload.url }
            else
                return rejectWithValue({ detail: 'Identifier not provided' })
            const response = await offeringCategoryAPI.get(params, templeId)
            response.data.templeId = templeId
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const offeringCategoryDeleteThunk = createAsyncThunk(
    'offeringCategory/delete',
    async (payload: IdAndUrlType) => {
        await offeringCategoryAPI.delete(payload.url)
        return payload.id
    }
)


const offeringCategorySlice = createSlice({
    name: 'offeringCategory',
    initialState: offeringCategoryAdapter.getInitialState(addOnIntitalState),
    reducers: {
        resetState(state) {
            state.api = {
                status: apiStatus.IDLE,
                error: null
            }
            offeringCategoryAdapter.removeAll(state)
        }
    },
    extraReducers: builder => {
        builder.addCase(offeringCategoryCreateThunk.pending, (state, action) => {
            state.api = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(offeringCategoryCreateThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            offeringCategoryAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(offeringCategoryCreateThunk.rejected, (state, action) => {
            state.api = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(offeringCategoryUpdateThunk.pending, (state, action) => {
            state.api = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(offeringCategoryUpdateThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            offeringCategoryAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(offeringCategoryUpdateThunk.rejected, (state, action) => {
            state.api = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(offeringCategoryListThunk.pending, (state, action) => {
            state.api = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(offeringCategoryListThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            offeringCategoryAdapter.upsertMany(state, action.payload)
        })
        builder.addCase(offeringCategoryListThunk.rejected, (state, action) => {
            state.api = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(offeringCategoryRetrieveThunk.pending, (state, action) => {
            state.api = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(offeringCategoryRetrieveThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            offeringCategoryAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(offeringCategoryRetrieveThunk.rejected, (state, action) => {
            state.api = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(offeringCategoryDeleteThunk.pending, (state, action) => {
            state.api = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(offeringCategoryDeleteThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            offeringCategoryAdapter.removeOne(state, action.payload)
        })
        builder.addCase(offeringCategoryDeleteThunk.rejected, (state, action) => {
            state.api = {
                status: apiStatus.FAILED,
                error: action.error.message
            }
        })
    }
})

export default offeringCategorySlice.reducer

export const { resetState } = offeringCategorySlice.actions

export const {
    selectAll: selectAllOfferingCategories,
    selectById: selectOfferingCategoryById,
    selectIds: selectOfferingCategoryIds,
} = offeringCategoryAdapter.getSelectors((state: AppState) => state.offeringCategory)

export const selectOfferingCategoryStatus = (state: AppState): IApiState => state.offeringCategory.api

export const selectCurrentTempleOfferingCategories = createSelector(
    [selectAllOfferingCategories, selectCurrentTempleId],
    (offeringCategories, currentTemple) => 
        offeringCategories.filter(offeringCategory => offeringCategory.templeId === currentTemple)
)
export const selectOfferingCategoriesByTempleId = createSelector(
    (state: AppState) => state, 
    (_:any, templeId: string|number) => templeId,
    (state, templeId) => {
        if (!templeId) return
        return selectAllOfferingCategories(state).filter(offeringCategory => offeringCategory.templeId === templeId)
    }
)


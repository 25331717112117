import { IDeityResponse } from "types/api/temple";
import { IDeityType } from "types/store/temple";

export const deityFormInitValues : IDeityType = {
    name: '',
    isMain: false,
    url:''
}

export const deityToForm = (deity: IDeityResponse) : IDeityType => {
    return {
        url: '',
        name: deity.name,
        isMain: deity.isMain
    }
}

export const getOfferingsError = (err: any) : string[] => {
    let errors : string[] = []
    if (err && 'offerings' in err && Array.isArray(err['offerings'])){
        err['offerings'].forEach(offeringErr => {
            if(typeof offeringErr === 'string')
                errors.push(offeringErr)
        })
    }
    return errors
}
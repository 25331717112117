import React, { useEffect } from 'react';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useStyles } from './Bookings.style'
import BookingsToolbar from './BookingsToolbar';
import BookingsTableHead from './BookingsTableHead';
import { apiDateFormat, apiTimeFormat, defaultDateFormat } from 'shared/constants/AppConst';
import IntlMessages from '@crema/utility/IntlMessages';
import { receiptListThunk, selectAllReceipt, selectFilterArgs, selectPagination, selectReceiptStatus, setFilters, receiptDownloadPdfThunk, markAsCompleteThunk } from 'store/reducers/temple/receiptSlice';
import { ReceiptResponseType } from 'types/api/receipt';
import { format as dateFormat, isBefore, parse as parseDate, parseISO } from 'date-fns'
import { selectCurrentTempleId } from 'store/reducers/temple/templeSlice';
import { RouteConfigComponentProps } from 'react-router-config';
import { fromToTimeToFormattedTime } from 'utils/fns';
import FullwidthTableCell from 'components/Table/FullwidthTableCell';
import FullWidthTableCell from 'components/Table/FullwidthTableCell';
import { useState } from 'react';
import { Box, Button, Checkbox, TableCell } from '@material-ui/core';
import ReceiptDialog from './ReceiptDialog';
import PaginatedTable from 'components/Table/PaginatedTable';
import { useMemo } from 'react';
import { IReceiptRow, IReceiptTime, ReceiptFilterArgsType, ReceiptRequestTypes } from 'store/reducers/temple/receiptSlice/types';

function createReceiptRow(receipt: ReceiptResponseType): IReceiptRow {
  let amount = receipt.meta.offering.price * receipt.meta.devoteeCount
  let row: IReceiptRow = {
    id: receipt.id,
    bookingDate: parseISO(receipt.order.createdAt),
    date: parseDate(receipt.date, apiDateFormat, new Date()),
    deity: receipt.meta.deity.name,
    category: receipt.meta.category?.name ?? '',
    offering: receipt.meta.offering.name,
    amount: amount,
    isCollecting: receipt.isCollecting,
    status: receipt.status
  }
  if (receipt.meta.dakshina)
    row.dakshina = receipt.meta.dakshina
  if (receipt.meta.time) {
    let offeringTime: IReceiptTime = { from: parseDate(receipt.meta.time.fromTime, apiTimeFormat, row.date) as Date }
    if (receipt.meta.time.toTime)
      offeringTime.to = parseDate(receipt.meta.time.toTime, apiTimeFormat, row.date)
    row.time = offeringTime
  }
  return row
}

// function descendingIReceiptTimeComparator(a?: IReceiptTime, b?: IReceiptTime) {
//   if (a && b) {
//     if (a.to && b.to) {
//       if (b.from < a.from && b.to < a.to) return -1
//       else return 1
//     }
//     if (b.from < a.from) return -1
//     else return 1
//   }
//   else if (a) return -1
//   else if (b) return 1
//   return 0
// }

// function descendingReceiptRowComparator(a: IReceiptRow, b: IReceiptRow, orderBy: keyof IReceiptRow) {
//   if (orderBy === 'offeringTime') {
//     return descendingIReceiptTimeComparator(a.offeringTime, b.offeringTime)
//   } else if (orderBy === 'dakshina') {
//     if (a.dakshina && b.dakshina) {
//       if (b.dakshina < a['dakshina']) return -1;
//       else return 1
//     }
//   }
//   else {
//     if (b[orderBy] < a[orderBy]) {
//       return -1;
//     }
//     if (b[orderBy] > a[orderBy]) {
//       return 1;
//     }
//   }
//   return 0;
// }



// function getReceiptComparator<Key extends keyof IReceiptRow>(
//   order: PaginatedOrder,
//   orderBy: Key,
// ): (a: IReceiptRow, b: IReceiptRow) => number {
//   return order === 'desc'
//     ? (a, b) => descendingReceiptRowComparator(a, b, orderBy)
//     : (a, b) => -descendingReceiptRowComparator(a, b, orderBy);
// }

// export const getReceiptRows = (
//   receipts: ReceiptResponseType[],
//   sortOrder: PaginatedOrder,
//   filterArgs: ReceiptFilterArgsType,
//   currentPage: number
// ): IReceiptRow[] => stableSort(
//   receipts.map(receipt => createReceiptRow(receipt)),
//   getReceiptComparator(sortOrder, filterArgs.orderBy as keyof IReceiptRow)
// ).slice(currentPage * filterArgs.pageSize, currentPage * filterArgs.pageSize + filterArgs.pageSize)

export const getReceiptRows = (
  receipts: ReceiptResponseType[],
  filterArgs: ReceiptFilterArgsType,
  currentPage: number
): IReceiptRow[] => 
  receipts
  .slice(currentPage * filterArgs.pageSize, currentPage * filterArgs.pageSize + filterArgs.pageSize)
  .map(receipt => createReceiptRow(receipt))


const isSelectableForCompletion = (row: IReceiptRow) => 
  isBefore(row.time?.from ?? row.date, new Date())


const BookingsTable: React.FC<RouteConfigComponentProps> = ({ location }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch()
  const [currentPage, setCurrentPage] = useState(0)
  const [maxPage, setMaxPage] = useState(0)
  const [receiptDialog, setReceiptDialog] = useState<{ open: boolean, id?: string | number }>({ open: false })
  const [selectedIds, setSelectedIds] = useState<(number | string)[]>([])
  const receipts = useAppSelector(selectAllReceipt)
  const filterArgs = useAppSelector(selectFilterArgs)
  const pagination = useAppSelector(selectPagination)
  const rows = useMemo(() => getReceiptRows(receipts, filterArgs, currentPage), [receipts, filterArgs, currentPage])
  const currentTempleId = useAppSelector(selectCurrentTempleId)
  const api = useAppSelector(selectReceiptStatus)

  useEffect(() => {
    if (currentTempleId)
      dispatch(receiptListThunk())
  }, [dispatch, currentTempleId, filterArgs])

  const handleReceiptDialogClose = () => setReceiptDialog(rd => { return { ...rd, open: false } })
  const handleReceiptOpen = (id: string | number) => setReceiptDialog({ open: true, id: id })

  const resetPages = () => {
    setCurrentPage(0)
    setMaxPage(0)
  }

  const handleRequestSort = (property: keyof IReceiptRow) => {
    const isAsc = filterArgs.orderBy === property && filterArgs.asc;
    resetPages()
    dispatch(setFilters({
      asc: isAsc ? false : true,
      orderBy: property
    }))
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    if (newPage > maxPage) {
      setMaxPage(newPage)
      await dispatch(receiptListThunk())
    }
    setCurrentPage(newPage)
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setFilters({ pageSize: parseInt(event.target.value, 10) }))
    dispatch(receiptListThunk())
    resetPages()
  };

  const changeFilter = (requestType: ReceiptRequestTypes, filters: Partial<ReceiptFilterArgsType>) => {
    dispatch(setFilters(filters))
    if (requestType === 'pdf' || requestType === 'priestPDF') 
      dispatch(receiptDownloadPdfThunk(requestType))
  }


  const selectAllHandler = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked)
      setSelectedIds([...rows.filter(e => e.status === 'pending' && isSelectableForCompletion(e)).map(row => row.id)])
    else
      setSelectedIds([])
  }

  const handleSelectId = (selectedId: number | string) => {
    if (selectedIds.findIndex(id => id === selectedId) === -1)
      setSelectedIds(ids => [...ids, selectedId])
    else
      setSelectedIds(ids => ids.filter(e => e !== selectedId))
  }

  const handleMarkAsComplete = async () => {
    await dispatch(markAsCompleteThunk(selectedIds))
    setSelectedIds([])
    // dispatch(receiptListThunk())
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
      <PaginatedTable
          tableHead={
            <BookingsTableHead
              order={filterArgs.asc ? 'asc' : 'desc'}
              orderBy={filterArgs.orderBy}
              onRequestSort={handleRequestSort}
              tableSelectAllProps={{
                selectAllHandler: selectAllHandler,
                indeterminate: selectedIds.length > 0 && selectedIds.length < receipts.length,
                checked: receipts.length > 0 && selectedIds.length === receipts.length
              }}
            />
          }
          toolbar={
            <BookingsToolbar changeFilter={changeFilter} receiptApi={api} />
          }
          api={api}
          action={
            <Box mx={4}>
              <Button
                color='primary'
                variant='contained'
                size='small'
                disableElevation
                disabled={selectedIds.length === 0}
                onClick={() => handleMarkAsComplete()}
              ><IntlMessages id="offering.markAsCompleted"/></Button>
            </Box>
          }
          pagination={{
            rowsPerPage: filterArgs.pageSize,
            currentPage: currentPage,
            nextIconDisabled: receipts.length > 0 && !pagination.next && currentPage === maxPage ? true : false,
            handleChangePage: handleChangePage,
            handleChangeRowsPerPage: handleChangeRowsPerPage
          }}
        >
          {rows
            
            .map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  key={row.id}
                >
                  <TableCell>
                    <Checkbox
                      color="primary"
                      disabled={row.status === "complete" || !isSelectableForCompletion(row)}
                      checked={row.status === "complete" || selectedIds.findIndex(e => e === row.id) !== -1}
                      onChange={() => handleSelectId(row.id)}
                      inputProps={{
                        "aria-labelledby": labelId
                      }}
                    />
                  </TableCell>
                  <FullwidthTableCell component="th" id={labelId} scope="row">
                    <Box className={classes.link} onClick={() => handleReceiptOpen(row.id)}>{`#${row.id}`}</Box>
                  </FullwidthTableCell>
                  <FullwidthTableCell align="left">{dateFormat(row.bookingDate, defaultDateFormat)}</FullwidthTableCell>
                  <FullwidthTableCell align="left">{dateFormat(row.date, defaultDateFormat)}</FullwidthTableCell>
                  <FullwidthTableCell align="left">
                    {row.time ?
                      fromToTimeToFormattedTime(row.time.from, row.time.to) : ''
                    }
                  </FullwidthTableCell>
                  <FullwidthTableCell align="left">{row.deity}</FullwidthTableCell>
                  <FullwidthTableCell align="left">{row.category}</FullwidthTableCell>
                  <FullwidthTableCell align="left">{row.offering}</FullwidthTableCell>
                  <FullWidthTableCell align="left">
                    <IntlMessages id={`common.${row.isCollecting ? 'yes' : 'no'}`} />
                  </FullWidthTableCell>
                  <FullWidthTableCell align="right">{row.dakshina}</FullWidthTableCell>
                  <FullWidthTableCell align="right">{row.amount}</FullWidthTableCell>
                </TableRow>
              );
            })}
        </PaginatedTable>
      </Paper>
      {receiptDialog.id && (
        <ReceiptDialog
          open={receiptDialog.open}
          handleClose={handleReceiptDialogClose}
          id={receiptDialog.id}
        />
      )}
    </div>
  );
}

export default BookingsTable
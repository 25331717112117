import IntlMessages from '@crema/utility/IntlMessages'
import { MenuItem } from '@material-ui/core'
import FormikTextField from 'components/Fields/FormikTextField'
import React, { useEffect } from 'react'
import { useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { deityRetrieveThunk, selectDeityById } from 'store/reducers/temple/deitySlice'
import { offeringCategoryListThunk, selectCurrentTempleOfferingCategories } from 'store/reducers/temple/offeringCategorySlice'
import { IdAndNameType } from 'types/api/receipt'
import { IDeityResponse } from 'types/api/temple'

interface BookingsDeitySelectProp {
    deityId: string | number,
    className: string,
    // handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void,
}

const getDeityCategories = (deity?: IDeityResponse) => {
    if (deity && deity.offerings && deity.offerings.length > 0) {
        const categories: IdAndNameType[] = []
        deity.offerings.forEach(offering => {
            if(!categories.find(category => category.id === offering.category.id))
                categories.push({ id: offering.category.id, name: offering.category.name })
        })
        categories.sort((a, b) => a.name.localeCompare(b.name))
        return categories
    }
}

const BookingsDeitySelect: React.FC<BookingsDeitySelectProp> = ({ className, deityId }) => {
    const categories = useAppSelector(selectCurrentTempleOfferingCategories)
    const deity = useAppSelector(state => selectDeityById(state, deityId))
    const deityCategories = useMemo(() => getDeityCategories(deity), [deity])
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(offeringCategoryListThunk({}))
    }, [dispatch])
    useEffect(() => {
        if (deityId)
            dispatch(deityRetrieveThunk({ id: deityId }))
    }, [dispatch, deityId])
    const renderMenuItem = (item: IdAndNameType) => (
        <MenuItem key={item.id} value={item.id}>
            {item.name}
        </MenuItem>
    )
    return (
        <FormikTextField
            select
            name='category'
            className={className}
            size="small"
            label={<IntlMessages id='common.category' />}
            margin="none"
        >
            <MenuItem value="">None</MenuItem>
            {deityCategories ? 
                deityCategories.map(category => renderMenuItem(category)) :
                categories.map(category => renderMenuItem(category))
            }
        </FormikTextField>
    )
}

export default BookingsDeitySelect
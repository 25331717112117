import { CardHeader, Divider } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import { MessageFormatElement, useIntl } from 'react-intl'
import { DonationReceiptResponseType } from 'types/api/receipt'
import { apiDateToFormattedDate, isoDateToFormattedDateTime } from 'utils/fns'

interface DonationReceiptProps {
    receipt: DonationReceiptResponseType
}

type ReceiptRowType = {
    type: 'content'
    messageId: string,
    value: string | number | MessageFormatElement[]
} | { type: 'divider' }

const getReceiptRows = (
    receipt: DonationReceiptResponseType,
    messages: Record<string, string> | Record<string, MessageFormatElement[]>
) => {
    const rows: ReceiptRowType[] = []
    rows.push({
        type: 'content',
        messageId: 'receipt.id',
        value: `#${receipt.id}`
    })
    rows.push({
        type: 'content',
        messageId: 'receipt.order.date.on',
        value: isoDateToFormattedDateTime(receipt.order.createdAt)
    })
    rows.push({
        type: 'content',
        messageId: 'common.status',
        value: messages[`booking.status.${receipt.order.status}`]
    })
    rows.push({ type: 'divider' })
    rows.push({
        type: 'content',
        messageId: 'receipt.order.user.by',
        value: receipt.order.user.fullName
    })
    rows.push({
        type: 'content',
        messageId: 'common.email',
        value: receipt.order.user.email ?? ''
    })
    rows.push({
        type: 'content',
        messageId: 'common.phone',
        value: receipt.order.user.phone ?? 'Na'
    })
    rows.push({ type: 'divider' })
    rows.push({
        type: 'content',
        messageId: 'donationReceipt.date',
        value: receipt.date ? apiDateToFormattedDate(receipt.date) : 'Na'
    })
    rows.push({
        type: 'content',
        messageId: 'receipt.devotee.count',
        value: receipt.meta.devoteeCount
    })
    rows.push({ type: 'divider' })
    rows.push({
        type: 'content',
        messageId: 'sidebar.donation',
        value: receipt.meta.donation.name
    })
    return rows
}

const getDevoteeRows = (
    receipt: DonationReceiptResponseType,
    messages: Record<string, string> | Record<string, MessageFormatElement[]>
) => {
    const rows: ReceiptRowType[] = []
    receipt.meta.devotees.forEach((devotee, index) => {
        rows.push({
            type: 'content',
            messageId: 'common.name',
            value: devotee.name
        })
        rows.push({
            type: 'content',
            messageId: 'star',
            value: messages[`star.${devotee.star}`]
        })
        if (devotee.dateOfBirth)
            rows.push({
                type: 'content',
                messageId: 'common.dob',
                value: apiDateToFormattedDate(devotee.dateOfBirth)
            })
        if (index < receipt.meta.devotees.length - 1)
            rows.push({ type: 'divider' })
    })
    return rows
}

const getAmountRows = (receipt: DonationReceiptResponseType) => {
    const rows: ReceiptRowType[] = [{
        type: 'content',
        messageId: 'common.amount',
        value: receipt.amount
    }]
    return rows
}

const DonationReceipt: React.FC<DonationReceiptProps> = ({ receipt }) => {
    const { messages } = useIntl()

    const getCardContent = (rows: ReceiptRowType[]) => {
        return (
            <Grid container spacing={4}>
                {rows.map((row, index) => (
                    row.type === 'content' ? (
                        <Grid item container justify="space-between" key={index} spacing={2}>
                            <Grid item>{messages[row.messageId]}</Grid>
                            <Grid item>{row.value}</Grid>
                        </Grid>
                    ) : <Grid item style={{ flexGrow: 1 }}><Divider /></Grid>
                ))}
            </Grid>
        )
    }

    return (
        <Grid container spacing={6}>
            <Grid item xs={12} sm={4}>
                <Card>
                    <CardContent>
                        {getCardContent(getReceiptRows(receipt, messages))}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Card>
                    <CardHeader title={<Box component="h5">{messages['devotees']}</Box>} />
                    <CardContent>
                        {getCardContent(getDevoteeRows(receipt, messages))}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Card>
                    <CardContent>
                        {getCardContent(getAmountRows(receipt))}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>

    )
}

export default DonationReceipt
import { FormControlLabel, Switch } from '@material-ui/core';
import { IRow, MemoizedBasicTable, StringNumberArrayType } from 'components/Table/BasicTable';
import usePermissionCheck from 'hooks/usePermissionCheck';
import React, { useCallback, useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
import { offeringUpdateThunk } from 'store/reducers/temple/offeringSlice';
import { IOfferingResponse, IOfferingTimeResponse } from 'types/api/temple';
import { capStr } from 'utils/fns';
import { PERMISSIONS, RESTRICTED_PATHS } from 'utils/permissions';
import { fmtBlockPeriod, getTimeChipText, offeringTimeToForm } from 'utils/temple/offering';

interface OfferingTableProps {
    editHandler?: ((id: string | number) => void | Promise<any>) & Function,
    deleteHandler?: ((id: string | number) => void | Promise<any>) & Function,
    deletingIds?: StringNumberArrayType,
    offerings: IOfferingResponse[]
}



const OfferingTable: React.FC<OfferingTableProps> = ({ editHandler, deleteHandler, deletingIds, offerings }) => {
    const dispatch = useAppDispatch()
    const hasEditPermission = usePermissionCheck(RESTRICTED_PATHS.OFFERINGS, PERMISSIONS.CHANGE)

    const getRows = useCallback((offerings: IOfferingResponse[], handleToggle: (offering: IOfferingResponse) => void): IRow[] => {
        let rows: IRow[] = []
        offerings.forEach(offering => {
            const row = {
                id: offering.id,
                values: [
                    offering.name,
                    capStr(offering.offeringType),
                    offering.category.name,
                    offering.price,
                    offering.offeringTimes?.length === 0 ? offering.perDayLimit ?? 'No limit' : 'N/a',
                    offering.blockPeriod && offering.blockUnit ? fmtBlockPeriod(offering.blockPeriod, offering.blockUnit) : 'N/a',
                    offering.offeringTimes ? (
                        <React.Fragment>
                            {offering.offeringTimes.map((offeringTime: IOfferingTimeResponse) => (
                                <React.Fragment key={offeringTime.id}>
                                    {getTimeChipText(offeringTimeToForm(offeringTime))}
                                    <br />
                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    ) : 'N/a',
                    (offering.isDakshinaRequired && offering.minDakshinaAmount) ? offering.minDakshinaAmount : "N/A"
                ]
            }
            if (hasEditPermission)
                row.values.push(
                    <React.Fragment>
                        <FormControlLabel
                            label={""}
                            checked={offering.isDakshinaRequired ? offering.isDakshinaRequired : false}
                            onChange={() => handleToggle(offering)}
                            control={
                                <Switch
                                    name={''}
                                />}
                        />
                    </React.Fragment>)
            rows.push(row)
        })
        return rows
    }, [offerings, hasEditPermission])

    const handleToggle = useCallback(async (offering: IOfferingResponse) => {
        await dispatch(offeringUpdateThunk({
            url: offering.url,
            data: { isDakshinaRequired: !offering.isDakshinaRequired }
        }))
    }, [dispatch])

    const rows = useMemo(() => getRows(offerings, handleToggle), [offerings, handleToggle])

    const headRow = useMemo(() => {
        const rows = [
            'common.name',
            'offering.offeringType',
            'offeringCategory',
            'common.price',
            'offering.perDayLimit',
            'offering.blockPeriod',
            'offering.time',
            'offering.minDakshinaAmount',
        ]
        if (hasEditPermission) rows.push('offering.isDakshinaRequired')
        return rows
    }, [hasEditPermission])

    return (
        <MemoizedBasicTable
            rows={rows}
            headRow={headRow}
            editHandler={editHandler}
            deleteHandler={deleteHandler}
            deletingIds={deletingIds}
        />
    );
};

export default OfferingTable;
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import IntlMessages from '@crema/utility/IntlMessages';
import { createStyles, makeStyles } from '@material-ui/core';
import { CremaTheme } from 'types/crema/AppContextPropsType';

interface PaginatedToolbarProps {
    handleShowFilter?: () => void,
    showFilters?: boolean,
    titleId: string
}

const useStyles = makeStyles((theme: CremaTheme) => (
    createStyles({
        hidden: {
            display: "none"
        },
    })
))


const PaginatedToolbar: React.FC<PaginatedToolbarProps> = ({ children, handleShowFilter, titleId, showFilters=false }) => {
    const classes = useStyles()
    return (
        <Box m={3}>
            <Grid container spacing={4}>
                <Grid container item xs={12} direction="row" justify="space-between" alignItems="center">
                    <Typography variant="h6" id="tableTitle" component="div">
                        <IntlMessages id={titleId} />
                    </Typography>
                    {handleShowFilter && (
                    <Tooltip title="Filter list">
                        <IconButton aria-label="filter list" onClick={() => handleShowFilter()}>
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>
                    )}
                </Grid>
                <Grid item xs={12} className={!showFilters ? classes.hidden : ''}>
                    {children}
                </Grid>
            </Grid>
        </Box>
    );
};


export default PaginatedToolbar;
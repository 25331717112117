import { CardHeader, Divider } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import { MessageFormatElement, useIntl } from 'react-intl'
import { ReceiptResponseType } from 'types/api/receipt'
import { apiDateToFormattedDate, apiFromToTimeToFormattedTime, isoDateToFormattedDateTime } from 'utils/fns'

interface BookingReceiptProps {
    receipt: ReceiptResponseType
}

type ReceiptRowType = {
    type: 'content'
    messageId: string,
    value: string | number | MessageFormatElement[]
} | { type: 'divider' }

const getReceiptRows = (
    receipt: ReceiptResponseType,
    messages: Record<string, string> | Record<string, MessageFormatElement[]>
) => {
    const rows: ReceiptRowType[] = []
    rows.push({
        type: 'content',
        messageId: 'receipt.id',
        value: `#${receipt.id}`
    })
    rows.push({
        type: 'content',
        messageId: 'receipt.order.date.on',
        value: isoDateToFormattedDateTime(receipt.order.createdAt)
    })
    rows.push({
        type: 'content',
        messageId: 'common.status',
        value: messages[`booking.status.${receipt.order.status}`]
    })
    rows.push({ type: 'divider' })
    rows.push({
        type: 'content',
        messageId: 'receipt.order.user.by',
        value: receipt.order.user.fullName
    })
    rows.push({
        type: 'content',
        messageId: 'common.email',
        value: receipt.order.user.email ?? ''
    })
    rows.push({
        type: 'content',
        messageId: 'common.phone',
        value: receipt.order.user.phone ?? 'Na'
    })
    rows.push({ type: 'divider' })
    rows.push({
        type: 'content',
        messageId: 'receipt.booking.date',
        value: apiDateToFormattedDate(receipt.date)
    })
    if (receipt.meta.time) {
        rows.push({
            type: 'content',
            messageId: 'receipt.booking.time',
            value: apiFromToTimeToFormattedTime(receipt.meta.time.fromTime, receipt.meta.time.toTime)
        })
    }
    rows.push({
        type: 'content',
        messageId: 'receipt.devotee.count',
        value: receipt.meta.devoteeCount
    })
    rows.push({ type: 'divider' })
    rows.push({
        type: 'content',
        messageId: 'deity',
        value: receipt.meta.deity.name
    })
    rows.push({
        type: 'content',
        messageId: 'common.offering',
        value: receipt.meta.offering.name
    })
    return rows
}

const getDevoteeRows = (
    receipt: ReceiptResponseType,
    messages: Record<string, string> | Record<string, MessageFormatElement[]>
) => {
    const rows: ReceiptRowType[] = []
    receipt.meta.devotees.forEach((devotee, index) => {
        rows.push({
            type: 'content',
            messageId: 'common.name',
            value: devotee.name
        })
        rows.push({
            type: 'content',
            messageId: 'star',
            value: messages[`star.${devotee.star}`]
        })
        if (devotee.dateOfBirth)
            rows.push({
                type: 'content',
                messageId: 'common.dob',
                value: apiDateToFormattedDate(devotee.dateOfBirth)
            })
        if (index < receipt.meta.devotees.length - 1)
            rows.push({ type: 'divider' })
    })
    return rows
}

const getAmountRows = (receipt: ReceiptResponseType) => {
    const rows: ReceiptRowType[] = []
    let total = 0
    if (receipt.meta.dakshina) {
        rows.push({
            type: 'content',
            messageId: 'receipt.dakshina',
            value: receipt.meta.dakshina
        })
        total += receipt.meta.dakshina
    }
    let amount = receipt.meta.offering.price * receipt.meta.devoteeCount
    total += amount
    rows.push({
        type: 'content',
        messageId: 'common.amount',
        value: `${receipt.meta.offering.price} x ${receipt.meta.devoteeCount} = ${amount}`
    })
    rows.push({ type: 'divider' })
    rows.push({
        type: 'content',
        messageId: 'invoice.total',
        value: total
    })
    return rows
}

const BookingReceipt: React.FC<BookingReceiptProps> = ({ receipt }) => {
    const { messages } = useIntl()

    const getCardContent = (rows: ReceiptRowType[]) => {
        return (
            <Grid container spacing={4}>
                {rows.map((row, index) => (
                    row.type === 'content' ? (
                        <Grid item container justify="space-between" key={index} spacing={2}>
                            <Grid item>{messages[row.messageId]}</Grid>
                            <Grid item>{row.value}</Grid>
                        </Grid>
                    ) : <Grid item style={{ flexGrow: 1 }}><Divider /></Grid>
                ))}
            </Grid>
        )
    }

    return (
        <Grid container spacing={6}>
            <Grid item xs={12} sm={4}>
                <Card>
                    <CardContent>
                        {getCardContent(getReceiptRows(receipt, messages))}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Card>
                    <CardHeader title={<Box component="h5">{messages['devotees']}</Box>} />
                    <CardContent>
                        {getCardContent(getDevoteeRows(receipt, messages))}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Card>
                    <CardContent>
                        {getCardContent(getAmountRows(receipt))}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>

    )
}

export default BookingReceipt
import IntlMessages from '@crema/utility/IntlMessages'
import { Box } from '@material-ui/core'
import { unwrapResult } from '@reduxjs/toolkit'
import GoBack from 'components/GoBack'
import FitContentPaper from 'components/Paper/FitContentPaper'
import { FormikHelpers } from 'formik'
import React, { useState } from 'react'
import { RouteConfigComponentProps } from 'react-router-config'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { deityAddOfferingThunk, deityCreateThunk, selectDeityStatus } from 'store/reducers/temple/deitySlice'
import { IOfferingResponse } from 'types/api/temple'
import { IDeityType } from 'types/store/temple'
import { getFieldErrors } from 'utils/api'
import { getOfferingsError } from 'utils/temple/deity'
import FormDeity from './FormDeity'
import useRestricter from 'hooks/useRestricter'
import { PERMISSIONS, RESTRICTED_PATHS } from 'utils/permissions'

const AddDeity : React.FC<RouteConfigComponentProps> = props => {
    const dispatch = useAppDispatch()
    const apiStatus = useAppSelector(selectDeityStatus)
    const [deityOfferings, setDeityOfferings] = useState<IOfferingResponse[]>([])
    const [alerts, setAlerts] = useState<TqAlert[]>([])
    const hasAddPermission = useRestricter(PERMISSIONS.ADD, RESTRICTED_PATHS.DEITY)

    const handleAddOfferings = (offerings: IOfferingResponse[]) =>
        setDeityOfferings([...deityOfferings, ...offerings])

    const handleRemoveOfferings = (offerings: IOfferingResponse[]) =>
        setDeityOfferings(deityOfferings.filter(obj1 => !offerings.some(obj2 => obj1.id === obj2.id)))

    const handleSubmit = (data: IDeityType, actions: FormikHelpers<IDeityType>) => {
        setAlerts([])
        dispatch(deityCreateThunk({data: data}))
            .then(unwrapResult)
            .then(response => {
                setAlerts(a => [...a, {message: `Deity ${response.name} created`, severity: 'success'}])
                if(deityOfferings.length > 0)
                    dispatch(deityAddOfferingThunk({url: response.url, offerings: deityOfferings}))
                        .then(unwrapResult)
                        .then(() => setAlerts(a => [...a, {message: `Offerings Assigned`, severity: 'success'}]))
                        .catch(err => {
                            let errors = getOfferingsError(err)
                            if(errors.length > 0)
                                errors.forEach(offeringErr => {
                                    setAlerts(a => [...a, {message: `Error assigining offering: ${offeringErr}`, severity: 'error'}])
                                })
                            else setAlerts(a => [...a, {message: `Error assigining offerings`, severity: 'error'}])
                        })
            }).catch(err => {
                Object.entries(getFieldErrors(data, err)).forEach(([key, value]) => 
                    actions.setFieldError(key, value))
            })
            .finally(() => {
                actions.setSubmitting(false)
                actions.resetForm()
                setDeityOfferings([])
            })
    }

    if(!hasAddPermission) return null

    return (
        <GoBack {...props}>
        <FitContentPaper>
            <Box component="h4" mb={4}><IntlMessages id="deity.add.title" /></Box>
            <FormDeity
                offerings={deityOfferings}
                handleAddOfferings={handleAddOfferings}
                handleRemoveOfferings={handleRemoveOfferings}
                api={apiStatus}
                onSubmitCb={handleSubmit}
                alerts={alerts}
                buttonLabelId="common.create"
            />
        </FitContentPaper>
    </GoBack>
    )
} 

export default AddDeity
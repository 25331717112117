import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import { apiStatus } from 'shared/constants/AppEnum'
import { AppState } from 'store/reduxStore'
import { IApiState } from 'store/types'
import { IOffering, IOfferingMetaType, IOfferingTime } from 'types/store/temple'
import { offeringAPI } from 'api/temple'
import { IOfferingResponse, TempleIdType } from 'types/api/temple'
import { selectCurrentTempleId } from './templeSlice'


const offeringAdapter = createEntityAdapter<IOfferingResponse>({
    sortComparer: (a, b) => a.name.localeCompare(b.name),
})

type addOnInitialStateType = {
    api: IApiState
    apiMeta: IApiState,
    apiTimes: IApiState
}
const addOnIntitalState: addOnInitialStateType = {
    api: {
        status: apiStatus.IDLE,
        error: null
    },
    apiMeta: {
        status: apiStatus.IDLE,
        error: null
    },
    apiTimes: {
        status: apiStatus.IDLE,
        error: null
    },
}

export const offeringListThunk = createAsyncThunk(
    'offering/list',
    async (payload: TempleIdType, { rejectWithValue, getState }) => {
        let templeId = payload.templeId ?? selectCurrentTempleId(getState() as AppState)
        try {
            const response = await offeringAPI.list(templeId)
            return response.data.map(offering => {
                offering.templeId = templeId
                return offering
            })
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const offeringAddThunk = createAsyncThunk(
    'offering/create',
    async (payload: IOffering & TempleIdType, { rejectWithValue, getState }) => {
        let templeId = payload.templeId ?? selectCurrentTempleId(getState() as AppState)
        try {
            const response = await offeringAPI.post(payload, templeId)
            response.data.templeId = templeId
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const offeringRetrieveThunk = createAsyncThunk(
    'offering/retrieve',
    async (payload: IdOrUrlType & TempleIdType, { rejectWithValue, getState }) => {
        let templeId = payload.templeId ?? selectCurrentTempleId(getState() as AppState)
        try {
            let params
            if ('id' in payload && payload.id)
                params = { itemId: payload.id }
            else if ('url' in payload)
                params = { url: payload.url }
            else
                return rejectWithValue({ detail: 'Identifier not provided' })
            const response = await offeringAPI.get(params, templeId)
            response.data.templeId = templeId
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const offeringUpdateThunk = createAsyncThunk(
    'offering/update',
    async (payload: PayloadWithUrl<Partial<IOffering>>, { rejectWithValue }) => {
        try {
            const response = await offeringAPI.update(payload.url, payload.data)
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const offeringDeleteThunk = createAsyncThunk(
    'offering/delete',
    async (payload: IdAndUrlType) => {
        await offeringAPI.delete(payload.url)
        return payload.id
    }
)

export const offeringMetaAddThunk = createAsyncThunk(
    'offering/meta/add',
    async (payload: PayloadWithUrl<IOfferingMetaType>, { rejectWithValue }) => {
        try {
            const response = await offeringAPI.addOfferingMeta(payload.url, payload.data)
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const offeringMetaRemoveThunk = createAsyncThunk(
    'offering/meta/remove',
    async (payload: PayloadWithUrl<IOfferingMetaType>, { rejectWithValue }) => {
        try {
            const response = await offeringAPI.removeOfferingMeta(payload.url, payload.data)
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const offeringTimeAddThunk = createAsyncThunk(
    'offering/time/add',
    async (payload: PayloadWithUrl<IOfferingTime>, { rejectWithValue }) => {
        try {
            const response = await offeringAPI.addOfferingTime(payload.url, payload.data)
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const offeringTimeUpdateThunk = createAsyncThunk(
    'offering/time/update',
    async (payload: PayloadWithUrl<Partial<IOfferingTime>>, { rejectWithValue }) => {
        try {
            const response = await offeringAPI.updateOfferingTime(payload.url, payload.data)
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const offeringTimeDeleteThunk = createAsyncThunk(
    'offering/time/delete',
    async (payload: UrlType) => {
        await offeringAPI.deleteOfferingTime(payload.url)
    }
)

const offeringSlice = createSlice({
    name: 'offering',
    initialState: offeringAdapter.getInitialState(addOnIntitalState),
    reducers: {
        resetState(state) {
            state.api = {
                status: apiStatus.IDLE,
                error: null
            }
            state.apiMeta = {
                status: apiStatus.IDLE,
                error: null
            }
            state.apiTimes = {
                status: apiStatus.IDLE,
                error: null
            }
            offeringAdapter.removeAll(state)
        }
    },
    extraReducers: builder => {
        builder.addCase(offeringListThunk.pending, (state, action) => {
            state.api = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(offeringListThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            offeringAdapter.upsertMany(state, action.payload)
        })
        builder.addCase(offeringListThunk.rejected, (state, action) => {
            state.api = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(offeringAddThunk.pending, (state, action) => {
            state.api = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(offeringAddThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            offeringAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(offeringAddThunk.rejected, (state, action) => {
            state.api = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(offeringUpdateThunk.pending, (state, action) => {
            state.api = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(offeringUpdateThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            offeringAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(offeringUpdateThunk.rejected, (state, action) => {
            state.api = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(offeringRetrieveThunk.pending, (state, action) => {
            state.api = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(offeringRetrieveThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            offeringAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(offeringRetrieveThunk.rejected, (state, action) => {
            state.api = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(offeringDeleteThunk.pending, (state, action) => {
            state.api = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(offeringDeleteThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            offeringAdapter.removeOne(state, action.payload)
        })
        builder.addCase(offeringDeleteThunk.rejected, (state, action) => {
            state.api = {
                status: apiStatus.FAILED,
                error: action.error.message
            }
        })
        builder.addCase(offeringMetaAddThunk.pending, (state, action) => {
            state.apiMeta = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(offeringMetaAddThunk.fulfilled, (state, action) => {
            state.apiMeta = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            offeringAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(offeringMetaAddThunk.rejected, (state, action) => {
            state.apiMeta = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(offeringMetaRemoveThunk.pending, (state, action) => {
            state.apiMeta = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(offeringMetaRemoveThunk.fulfilled, (state, action) => {
            state.apiMeta = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            offeringAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(offeringMetaRemoveThunk.rejected, (state, action) => {
            state.apiMeta = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(offeringTimeAddThunk.pending, (state, action) => {
            state.apiTimes = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(offeringTimeAddThunk.fulfilled, (state, action) => {
            state.apiTimes = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
        })
        builder.addCase(offeringTimeAddThunk.rejected, (state, action) => {
            state.apiTimes = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(offeringTimeUpdateThunk.pending, (state, action) => {
            state.apiTimes = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(offeringTimeUpdateThunk.fulfilled, (state, action) => {
            state.apiTimes = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
        })
        builder.addCase(offeringTimeUpdateThunk.rejected, (state, action) => {
            state.apiTimes = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(offeringTimeDeleteThunk.pending, (state, action) => {
            state.apiTimes = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(offeringTimeDeleteThunk.fulfilled, (state, action) => {
            state.apiTimes = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
        })
        builder.addCase(offeringTimeDeleteThunk.rejected, (state, action) => {
            state.apiTimes = {
                status: apiStatus.FAILED,
                error: action.error.message
            }
        })
    }
})

export default offeringSlice.reducer

export const {
    selectAll: selectAllOffering,
    selectById: selectOfferingById,
    selectIds: selectOfferingIds,
} = offeringAdapter.getSelectors((state: AppState) => state.offering)

export const { resetState } = offeringSlice.actions

export const selectOfferingStatus = (state: AppState): IApiState => state.offering.api
export const selectOfferingTimeStatus = (state: AppState) => state.offering.apiTimes
export const selectOfferingMetaStatus = (state: AppState) => state.offering.apiMeta

export const selectCurrentTempleOfferings = createSelector(
    [selectAllOffering, selectCurrentTempleId],
    (offerings, currentTemple) => offerings.filter(offering => offering.templeId === currentTemple)
)
export const selectOfferingsByTempleId = createSelector(
    (state: AppState) => state,
    (_:any, templeId: string|number) => templeId,
    (state, templeId) => {
        if (templeId)
            return selectAllOffering(state).filter(offering => offering.templeId === templeId)
    }  
)
import IntlMessages from '@crema/utility/IntlMessages'
import { MenuItem } from '@material-ui/core'
import FormikTextField from 'components/Fields/FormikTextField'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { donationCategoryListThunk, selectCurrentTempleDonationCategories } from 'store/reducers/temple/donationCategorySlice'
import { IdAndNameType } from 'types/api/receipt'


interface DonationReceiptCategorySelectProp {
    className: string,
    // handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void,
}


const DonationReceiptCategorySelect: React.FC<DonationReceiptCategorySelectProp> = ({ className }) => {
    const categories = useAppSelector(selectCurrentTempleDonationCategories)

    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(donationCategoryListThunk({}))
    }, [dispatch])

    const renderMenuItem = (item: IdAndNameType) => (
        <MenuItem key={item.id} value={item.id}>
            {item.name}
        </MenuItem>
    )
    return (
        <FormikTextField
            select
            name='category'
            className={className}
            size="small"
            label={<IntlMessages id='common.category' />}
            margin="none"
        >
            <MenuItem value="">None</MenuItem>
            {categories.map(category => renderMenuItem(category))}
        </FormikTextField>
    )
}

export default DonationReceiptCategorySelect
import { configureStore, Middleware } from '@reduxjs/toolkit'

import historyReducer, {historyMiddleware} from './reducers/history'
import authReducer from './reducers/auth/authSlice'
import commonReducer from './reducers/common/commonSlice'
import settingsReducer from './reducers/settings/settingsSlice'
import templeReducer from './reducers/temple/templeSlice'
import offeringCategoryReducer from './reducers/temple/offeringCategorySlice'
import offeringReducer from './reducers/temple/offeringSlice'
import deityReducer from './reducers/temple/deitySlice'
import orderReducer from './reducers/temple/orderSlice'
import receiptReducer from './reducers/temple/receiptSlice'
import bankAccountReducer from './reducers/temple/bankAccountSlice'
import donationCategoryReducer from './reducers/temple/donationCategorySlice'
import donationReducer from './reducers/temple/donationSlice'
import offeringFinancialReducer from './reducers/temple/offeringFinancialSlice'
import donationReceiptReducer from './reducers/temple/donationReceiptSlice'
import staffTempleAdminReducer from './reducers/temple/staffTempleAdminSlice'

import feeSplitNameReducer from './reducers/report_app/feeSplitNameSlice'
import feeSplitConfigReducer from './reducers/report_app/feeSplitConfigSlice'
import usersReducer from './reducers/temple/usersSlice'

const middlewares : Middleware[] = [
    historyMiddleware
]

export const store = configureStore({
    reducer: {
        router: historyReducer,
        auth: authReducer,
        common: commonReducer,
        settings: settingsReducer,
        temple: templeReducer,
        offeringCategory: offeringCategoryReducer,
        offering: offeringReducer,
        deity: deityReducer,
        order: orderReducer,
        receipt: receiptReducer,
        donationReceipt: donationReceiptReducer,
        bankAccount: bankAccountReducer,
        donationCategory:donationCategoryReducer,
        donation:donationReducer,
        offeringFinancial:offeringFinancialReducer,
        feeSplitName: feeSplitNameReducer,
        feeSplitConfig: feeSplitConfigReducer,
        staffTempleAdmin: staffTempleAdminReducer,
        users: usersReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(...middlewares)
})

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
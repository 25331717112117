import IntlMessages from '@crema/utility/IntlMessages'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import RedButton from 'components/Button/RedButton'
import EnhancedTableToolbar from 'components/Table/EnhancedTableToolbar'
import OfferingTable from 'components/Temple/OfferingTable'
import usePermissionCheck from 'hooks/usePermissionCheck'
import React, { MouseEventHandler, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { apiStatus } from 'shared/constants/AppEnum'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { offeringDeleteThunk, offeringListThunk, selectCurrentTempleOfferings, selectOfferingStatus } from 'store/reducers/temple/offeringSlice'
import { IOfferingResponse } from 'types/api/temple'
import { PERMISSIONS, RESTRICTED_PATHS } from 'utils/permissions'


const ListOffering: React.FC<RouteComponentProps> = ({ history, location }) => {
    const dispatch = useAppDispatch()
    const [deleteItem, setDeleteItem] = useState<IOfferingResponse | null>(null)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [deleteIds, setDeleteIds] = useState<(string | number)[]>([])
    const { pathname } = location
    const offerings = useAppSelector(selectCurrentTempleOfferings)
    const api = useAppSelector(selectOfferingStatus)
    const permissions = usePermissionCheck(RESTRICTED_PATHS.OFFERINGS)

    useEffect(() => {
        dispatch(offeringListThunk({}))
    }, [dispatch])

    const addNewHandler: MouseEventHandler<HTMLButtonElement> = () => history.push(`${pathname}/add`)
    const editHandler = (id: string | number) => history.push(`${pathname}/edit/${id}`)

    const handlerOpenDialog = (id: string | number) => {
        let item = offerings.find(o => o.id === id)
        if (item) {
            setDeleteItem(item)
            setDialogOpen(true)
        }
    }

    const handlerCloseDialog = (canDelete?: boolean) => {
        if (canDelete && deleteItem) {
            setDeleteIds([...deleteIds, deleteItem.id])
            dispatch(offeringDeleteThunk(deleteItem))
                .finally(() => {
                    setDeleteIds(deleteIds.filter(id => id !== deleteItem.id))
                    setDeleteItem(null)
                })
        }
        setDialogOpen(false)
    }

    return (
        <Box>
            <Paper style={{ width: 'max-content' }}>
                <EnhancedTableToolbar
                    title={<IntlMessages id={'offering.title'} />}
                    showFilter={false}
                    addNew={permissions.includes(PERMISSIONS.ADD) ? { handle: addNewHandler } : undefined}
                />
                {api.status === apiStatus.LOADING && <LinearProgress />}
                <OfferingTable
                    editHandler={permissions.includes(PERMISSIONS.CHANGE) ? editHandler : undefined}
                    deleteHandler={permissions.includes(PERMISSIONS.DELETE) ? handlerOpenDialog : undefined}
                    deletingIds={permissions.includes(PERMISSIONS.DELETE) ? deleteIds : undefined}
                    offerings={offerings}
                />
            </Paper>
            <Dialog open={Boolean(dialogOpen && deleteItem)}>
                <DialogTitle>{`Are you sure you want to delete ${deleteItem?.name}?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Warning: You cannot reverse this action!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={() => handlerCloseDialog(false)}>
                        <IntlMessages id="common.cancel" />
                    </Button>
                    <RedButton variant="outlined" onClick={() => handlerCloseDialog(true)} autoFocus>
                        <IntlMessages id="common.delete" />
                    </RedButton>
                </DialogActions>
            </Dialog>
        </Box>
    );
}


export default ListOffering
import { createAsyncThunk, createEntityAdapter, createSlice, isAnyOf ,createSelector} from "@reduxjs/toolkit";
import { donationCategoryAPI } from "api/temple";
import { apiStatus } from "shared/constants/AppEnum";
import { AppState } from "store/reduxStore";
import { IApiState } from "store/types";
import { IDonationCategoryResponse, TempleIdType } from "types/api/temple";
import { IDonationCategory } from "types/store/temple";
import { selectCurrentTempleId } from "./templeSlice";



const donationCategoryAdapter = createEntityAdapter<IDonationCategoryResponse>({
    sortComparer:(a,b) => a.name.localeCompare(b.name),
})

type InitialStateType ={
    api :IApiState
}

const addOnIntitalState :InitialStateType ={
    api:{
        status:apiStatus.IDLE,
        error:null,
    }
}


export const donationCategoryListThunk =createAsyncThunk(
    'donationCategory/list',
    async(payload:TempleIdType,{rejectWithValue,getState}) => {
        let templeId = payload.templeId ?? selectCurrentTempleId(getState() as AppState)
        try{
            const response = await donationCategoryAPI.list(templeId)
            return response.data.map(donationCategory =>{
                donationCategory.templeId = templeId
                return donationCategory
            })
        }catch(err:any){
            return rejectWithValue(err.response.data)
        }
    }
)

export const donationCategoryCreateThunk = createAsyncThunk(
    'donationCategory/create',
    async(payload:IDonationCategory & TempleIdType,{rejectWithValue,getState}) => {
        let templeId = payload.templeId ?? selectCurrentTempleId(getState() as AppState)
        try{
            const response =await donationCategoryAPI.post(payload,templeId)
            response.data.templeId = templeId
            return response.data
        }
        catch(err:any){
            return rejectWithValue(err.response.data)
        }
    }
)

export const donationCategoryUpdateThunk = createAsyncThunk(
    'donationCategory/',
    async(payload:PayloadWithUrl<Partial<IDonationCategory>>,{rejectWithValue}) => {
        try{
            const response = await donationCategoryAPI.update(payload.url,payload.data) 
            return response.data
        }
        catch(err:any){
            return rejectWithValue(err.response.data)
        }
    }
)

export const donationCategoryRetrieveThunk = createAsyncThunk(
    'donationCategory/retrive',
    async(payload:IdOrUrlType & TempleIdType,{rejectWithValue,getState}) => {
        let templeId = payload.templeId ?? selectCurrentTempleId(getState() as AppState)
        try {
            let params
            if('id' in payload && payload.id)
                params ={ itemId:payload.id }
            else if('url' in payload)
                params ={ url:payload.url }
            else
                return rejectWithValue({detail: 'Identifier not provided'})
                const response =await donationCategoryAPI.get(params,templeId)
                response.data.templeId = templeId
                return response.data
        } catch (err:any) {
                return rejectWithValue(err.response.data)
        }
    }
)

export const donationCategoryDeleteThunk = createAsyncThunk(
    'donationCategory/delete',
    async(payload:IdAndUrlType) => {
        await donationCategoryAPI.delete(payload.url)
        return payload.id
    }
)

const donationCategorySlice = createSlice({
    name:"donationCategory",
    initialState:donationCategoryAdapter.getInitialState(addOnIntitalState),
    reducers:{
        resetState(state) {
            state.api ={
                status:apiStatus.IDLE,
                error:null
            }
            donationCategoryAdapter.removeAll(state)
        }
    },
    extraReducers:builder => {
        builder.addCase(donationCategoryListThunk.fulfilled,(state,action) =>{
            state.api = {
                status:apiStatus.SUCCEEDED,
                error:null
            }
            donationCategoryAdapter.upsertMany(state,action.payload)
        })
        builder.addCase(donationCategoryDeleteThunk.rejected,(state,action) =>{
            state.api ={
                status:apiStatus.FAILED,
                error:action.error.message
            }
        })
        builder.addCase(donationCategoryDeleteThunk.fulfilled,(state,action)=>{
            state.api ={
                status:apiStatus.SUCCEEDED,
                error:null
            }
            donationCategoryAdapter.removeOne(state,action.payload)
        })
        builder.addMatcher(
            isAnyOf(
                donationCategoryCreateThunk.pending,
                donationCategoryListThunk.pending,
                donationCategoryRetrieveThunk.pending,
                donationCategoryDeleteThunk.pending,
                donationCategoryUpdateThunk.pending
            ),(state,action) => {
                state.api ={
                    status:apiStatus.LOADING,
                    error:null
                }
            }
        )
        builder.addMatcher(
            isAnyOf(
                donationCategoryListThunk.rejected,
                donationCategoryRetrieveThunk.rejected,
                donationCategoryUpdateThunk.rejected,
                donationCategoryCreateThunk.rejected,
            ),(state,action) =>{
                state.api ={
                    status:apiStatus.FAILED,
                    error:action.payload
                }
            }
        )
        builder.addMatcher(
            isAnyOf(
                donationCategoryCreateThunk.fulfilled,
                donationCategoryUpdateThunk.fulfilled,   
                donationCategoryRetrieveThunk.fulfilled,          
            ),(state,action) =>{
                state.api = {
                        status:apiStatus.SUCCEEDED,
                        error:null
                }
                donationCategoryAdapter.upsertOne(state,action.payload)
            }
        )
    }
})

export default donationCategorySlice.reducer
export const  {
    selectAll:selectAllDonationCategories,
    selectById:selectDonationCategoryById,
    selectIds:selectDonationCategoryIds,
} = donationCategoryAdapter.getSelectors((state:AppState)=>state.donationCategory)

export const selectDonationCategoryStatus = (state:AppState):IApiState => state.donationCategory.api

export const selectCurrentTempleDonationCategories = createSelector(
    [selectAllDonationCategories,selectCurrentTempleId],
    (donationCategories,currentTemple) => 
        donationCategories.filter(donationCategory => donationCategory.templeId === currentTemple)
)

export const selectDonationCategoriesByTempleId = createSelector(
    (state:AppState) => state ,
    (_:any,templeId: string|number) => templeId,
    (state,templeId) =>{
        if(!templeId) return
        return selectAllDonationCategories(state).filter(donationCategory => donationCategory.templeId === templeId)
    }
)
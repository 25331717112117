import React, { useEffect } from 'react';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useStyles } from './DonationBooking.style'
import { apiDateFormat, defaultDateFormat } from 'shared/constants/AppConst';
import { format as dateFormat, parse as parseDate, parseISO } from 'date-fns'
import { selectCurrentTempleId } from 'store/reducers/temple/templeSlice';
import { RouteConfigComponentProps } from 'react-router-config';
import FullWidthTableCell from 'components/Table/FullwidthTableCell';
import { useState } from 'react';
import { Box } from '@material-ui/core';
import ReceiptDialog from './ReceiptDialog';
import PaginatedTable from 'components/Table/PaginatedTable';
import { useMemo } from 'react';
import { DonationReceiptResponseType } from 'types/api/receipt';
import { DonationReceiptFilterArgsType, IDonationReceiptRow } from 'store/reducers/temple/donationReceiptSlice/types';
import { donationReceiptDownloadPdfThunk, donationReceiptListThunk, selectAllDonationReceipt, selectDonationFilterArgs, selectDonationPagination, selectDonationReceiptStatus, setDonationReceiptFilters } from 'store/reducers/temple/donationReceiptSlice';
import { ReceiptRequestTypes } from 'store/reducers/temple/receiptSlice/types';
import DonationReceiptTableHead from './DonationReceiptTableHead';
import DonationReceiptToolbar from './DonationReceiptToolbar';

function createReceiptRow(receipt: DonationReceiptResponseType): IDonationReceiptRow {
  let row: IDonationReceiptRow = {
    id: receipt.id,
    bookingDate: parseISO(receipt.order.createdAt),
    date: receipt.date ? parseDate(receipt.date, apiDateFormat, new Date()) : undefined,
    category: receipt.meta.category?.name ?? '',
    donation: receipt.meta.donation.name,
    amount: receipt.amount,
  }
  return row
}


export const getReceiptRows = (
  receipts: DonationReceiptResponseType[],
  filterArgs: DonationReceiptFilterArgsType,
  currentPage: number
): IDonationReceiptRow[] => 
  receipts
  .slice(currentPage * filterArgs.pageSize, currentPage * filterArgs.pageSize + filterArgs.pageSize)
  .map(receipt => createReceiptRow(receipt))



const DonationBookingTable: React.FC<RouteConfigComponentProps> = ({ location }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch()
  const [currentPage, setCurrentPage] = useState(0)
  const [maxPage, setMaxPage] = useState(0)
  const [receiptDialog, setReceiptDialog] = useState<{ open: boolean, id?: string | number }>({ open: false })
  const receipts = useAppSelector(selectAllDonationReceipt)
  const filterArgs = useAppSelector(selectDonationFilterArgs)
  const pagination = useAppSelector(selectDonationPagination)
  const rows = useMemo(() => getReceiptRows(receipts, filterArgs, currentPage), [receipts, filterArgs, currentPage])
  const currentTempleId = useAppSelector(selectCurrentTempleId)
  const api = useAppSelector(selectDonationReceiptStatus)

  useEffect(() => {
    if (currentTempleId)
      dispatch(donationReceiptListThunk())
  }, [dispatch, currentTempleId, filterArgs])

  const handleReceiptDialogClose = () => setReceiptDialog(rd => { return { ...rd, open: false } })
  const handleReceiptOpen = (id: string | number) => setReceiptDialog({ open: true, id: id })

  const resetPages = () => {
    setCurrentPage(0)
    setMaxPage(0)
  }

  const handleRequestSort = (property: keyof IDonationReceiptRow) => {
    const isAsc = filterArgs.orderBy === property && filterArgs.asc;
    resetPages()
    dispatch(setDonationReceiptFilters({
      asc: isAsc ? false : true,
      orderBy: property
    }))
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    if (newPage > maxPage) {
      setMaxPage(newPage)
      await dispatch(donationReceiptListThunk())
    }
    setCurrentPage(newPage)
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setDonationReceiptFilters({ pageSize: parseInt(event.target.value, 10) }))
    dispatch(donationReceiptListThunk())
    resetPages()
  };

  const changeFilter = (requestType: ReceiptRequestTypes, filters: Partial<DonationReceiptFilterArgsType>) => {
    dispatch(setDonationReceiptFilters(filters))
    if (requestType === 'pdf' || requestType === 'priestPDF') 
      dispatch(donationReceiptDownloadPdfThunk(requestType))
  }




  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
      <PaginatedTable
          tableHead={
            <DonationReceiptTableHead
              order={filterArgs.asc ? 'asc' : 'desc'}
              orderBy={filterArgs.orderBy}
              onRequestSort={handleRequestSort}
            />
          }
          toolbar={
            <DonationReceiptToolbar changeFilter={changeFilter} receiptApi={api} />
          }
          api={api}
          pagination={{
            rowsPerPage: filterArgs.pageSize,
            currentPage: currentPage,
            nextIconDisabled: receipts.length > 0 && !pagination.next && currentPage === maxPage ? true : false,
            handleChangePage: handleChangePage,
            handleChangeRowsPerPage: handleChangeRowsPerPage
          }}
        >
          {rows
            
            .map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  key={row.id}
                >
                  
                  <FullWidthTableCell component="th" id={labelId} scope="row">
                    <Box className={classes.link} onClick={() => handleReceiptOpen(row.id)}>{`#${row.id}`}</Box>
                  </FullWidthTableCell>
                  <FullWidthTableCell align="left">{dateFormat(row.bookingDate, defaultDateFormat)}</FullWidthTableCell>
                  <FullWidthTableCell align="left">{row.date ? dateFormat(row.date, defaultDateFormat) : 'Na'}</FullWidthTableCell>
                  <FullWidthTableCell align="left">{row.category}</FullWidthTableCell>
                  <FullWidthTableCell align="left">{row.donation}</FullWidthTableCell>
                  <FullWidthTableCell align="right">{row.amount}</FullWidthTableCell>
                </TableRow>
              );
            })}
        </PaginatedTable>
      </Paper>
      {receiptDialog.id && (
        <ReceiptDialog
          open={receiptDialog.open}
          handleClose={handleReceiptDialogClose}
          id={receiptDialog.id}
        />
      )}
    </div>
  );
}

export default DonationBookingTable
import { Loader } from '@crema'
import Error404 from 'components/ErrorPage/Error404'
import GoBack from 'components/GoBack'
import React, { useEffect } from 'react'
import { RouteConfigComponentProps } from 'react-router-config'
import { apiStatus } from 'shared/constants/AppEnum'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { donationReceiptRetrieveThunk, selectDonationReceiptById, selectDonationReceiptStatus } from 'store/reducers/temple/donationReceiptSlice'
import DonationReceipt from './DonationReceipt'

interface RouteParams {
    id: string | undefined
}

const DonationReceiptLayout: React.FC<RouteConfigComponentProps<RouteParams>> = props => {
    const { id } = props.match.params
    const dispatch = useAppDispatch();
    const receipt = useAppSelector(state => selectDonationReceiptById(state, id ?? ''))
    const receiptStatus = useAppSelector(selectDonationReceiptStatus)
    useEffect(() => {
        if (id)
            dispatch(donationReceiptRetrieveThunk({ id: id }))
    }, [dispatch, id])

    return (
        receiptStatus.status === apiStatus.LOADING ?
            <Loader /> :
            receipt ?
                (
                    <GoBack {...props}>
                        <DonationReceipt receipt={receipt} />
                    </GoBack>
                ) : <Error404 />
    )
}

export default DonationReceiptLayout
import { CommonTableHeadCell, CommonTableOrder } from 'components/Table/common/types';
import PaginatedTableHead, { TableSelectAllProps } from 'components/Table/PaginatedTable/PaginatedTableHead';
import React from 'react'
import { IReceiptRow } from 'store/reducers/temple/receiptSlice/types';

const headCells: CommonTableHeadCell<IReceiptRow>[] = [
  { id: 'id', align: 'left', disablePadding: false, label: 'Receipt Id', hasSorting: true },
  { id: 'bookingDate', align: 'left', disablePadding: false, label: 'Date of Booking', hasSorting: true },
  { id: 'date', align: 'left', disablePadding: false, label: 'Date of Offering', hasSorting: true },
  { id: 'time', align: 'left', disablePadding: false, label: 'Offering Time', hasSorting: true },
  { id: 'deity', align: 'left', disablePadding: false, label: 'Deity', hasSorting: true },
  { id: 'category', align: 'left', disablePadding: false, label: 'Category',  hasSorting: true },
  { id: 'offering', align: 'left', disablePadding: false, label: 'Offering', hasSorting: true },
  { id: 'isCollecting', align: 'left', disablePadding: false, label: 'Prasad Collection', hasSorting: false},
  { id: 'dakshina', align: 'right', disablePadding: false, label: 'Dakshina', hasSorting: false },
  { id: 'amount', align: 'right', disablePadding: false, label: 'Amount', hasSorting: false },
];


interface BookingsTableHeadProps {
  onRequestSort: (property: keyof IReceiptRow) => void;
  order: CommonTableOrder;
  orderBy: keyof IReceiptRow;
  tableSelectAllProps: TableSelectAllProps
}

function BookingsTableHead(props: BookingsTableHeadProps) {
  const { order, orderBy, onRequestSort, tableSelectAllProps } = props;
  return (
   <PaginatedTableHead 
    headCells={headCells} 
    orderBy={orderBy} 
    order={order} 
    sortHandler={onRequestSort} 
    selectAllProps={tableSelectAllProps}
    />
  );
}


export default BookingsTableHead
import IntlMessages from '@crema/utility/IntlMessages'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React, { ReactElement } from 'react'
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';

export type RowValueType = (string | number | ReactElement)[]

export type StringNumberArrayType = (string | number)[]

export interface IRow {
    id: string | any,
    values: RowValueType
}

interface BasicTableProps {
    rows: IRow[],
    headRow: RowValueType,
    editHandler?: ((id: string | number) => void | Promise<any>) & Function,
    deleteHandler?: ((id: string | number) => void | Promise<any>) & Function,
    deletingIds?: StringNumberArrayType
}

const BasicTable: React.FC<BasicTableProps> = (props) => {
    const { rows, headRow, editHandler, deleteHandler, deletingIds } = props
    const hasActions = Boolean(editHandler || deleteHandler)
    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {headRow.slice(0, 1).map((heading, index) => (
                            <TableCell size='small' key={index}><IntlMessages id={heading} /></TableCell>
                        ))}
                        {headRow.slice(1).map((heading, index) => (
                            <TableCell size='small' key={index} align="right"><IntlMessages id={heading} /></TableCell>
                        ))}
                        {hasActions && <TableCell size='small'></TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(row => (
                        <TableRow key={row.id}>
                            {row.values.slice(0, 1).map((val, index) => (
                                <TableCell key={index} component="th" scope="row" size='small'>
                                    {val}
                                </TableCell>
                            ))}
                            {row.values.slice(1).map((val, index) => (
                                <TableCell key={index} align="right" size='small'>
                                    {val}
                                </TableCell>
                            ))}
                            {hasActions && (
                                <TableCell align="right">
                                    <Box
                                        minWidth="max-content"
                                        width="100%"
                                        justifyContent="flex-end"
                                        flexDirection="row"
                                    >
                                        {editHandler && (
                                            <IconButton
                                                aria-label="edit"
                                                onClick={() => editHandler(row.id)}>
                                                <EditIcon />
                                            </IconButton>
                                        )}
                                        {deleteHandler && (
                                            deletingIds && deletingIds.indexOf(row.id) !== -1 ? (
                                                <IconButton>
                                                    <CircularProgress size="1.5rem" />
                                                </IconButton>
                                            ) : (
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={() => deleteHandler(row.id)}
                                                >
                                                    <RemoveCircleOutlineIcon />
                                                </IconButton>
                                            )
                                        )}
                                    </Box>
                                </TableCell>)}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default BasicTable
export const MemoizedBasicTable = React.memo(BasicTable)
import React from 'react';
import Box from '@material-ui/core/Box';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { receiptRetrieveThunk, selectReceiptById } from 'store/reducers/temple/receiptSlice';
import { useEffect } from 'react';
import BookingReceipt from '../BookingReceipt';
import Container from '@material-ui/core/Container';
import FullWidthDialog from 'components/Dialog/FullWidthDialog';
// import { IApiState } from 'store/types';

interface ReceiptDialogProps {
    id: number | string,
    open: boolean,
    handleClose: () => void,
}

const ReceiptDialog: React.FC<ReceiptDialogProps> =
    ({ id, open, handleClose }) => {
        const dispatch = useAppDispatch()
        const receipt = useAppSelector(state => selectReceiptById(state, id))
        useEffect(() => {
            dispatch(receiptRetrieveThunk({id: id}))
        }, [dispatch, id])
        return (
            <FullWidthDialog open={open} handleClose={handleClose}>
                <Container>
                    <Box mb={10}>
                        {receipt && <BookingReceipt receipt={receipt} />}
                    </Box>
                </Container>
            </FullWidthDialog>
        );
    };

export default ReceiptDialog;
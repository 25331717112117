import React, { useEffect, useState } from 'react'
import IconButton from '@material-ui/core/IconButton';
import IntlMessages from '@crema/utility/IntlMessages';
import Grid from '@material-ui/core/Grid';
import { Form, Formik } from 'formik';
import { ReceiptFilterFormType } from 'types/store/receipt';
import FormikTextField from 'components/Fields/FormikTextField';
import { defaultDateFormat } from 'shared/constants/AppConst';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import { useIntl } from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles } from '@material-ui/core';
import { CremaTheme } from 'types/crema/AppContextPropsType';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { deityListThunk, selectCurrentTempleDeities } from 'store/reducers/temple/deitySlice';
import { filterInitValues, formToFilterArgs } from 'utils/temple/receipt';
import BookingsOfferingSelect from './BookingsOfferingSelect';
import * as yup from 'yup'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import { IApiState } from 'store/types';
import { apiStatus } from 'shared/constants/AppEnum';
import PaginatedToolbar from 'components/Table/PaginatedTable/PaginatedToolbar';
import BookingsDeitySelect from './BookingsDeitySelect';
import { ReceiptFilterArgsType, ReceiptRequestTypes } from 'store/reducers/temple/receiptSlice/types';

interface EnhancedTableToolbarProps {
  changeFilter: (requestType: ReceiptRequestTypes, filters: Partial<ReceiptFilterArgsType>) => void,
  receiptApi: IApiState,
}

const useStyles = makeStyles((theme: CremaTheme) => (
  createStyles({
    field: {
      flexGrow: 1
    },
    fieldRoot: {
      // width: 180,
      // [theme.breakpoints.down('sm')]: {
      //   width: '100%'
      // },
      width: '100%'
    },
    hidden: {
      display: "none"
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    formGridRoot: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: "column"
      },
    }
  })
))

const BookingsToolbar: React.FC<EnhancedTableToolbarProps> = ({ changeFilter, receiptApi }) => {
  const [showFilters, setShowFilters] = useState(false);
  const handleShowFilter = () => setShowFilters(!showFilters)
  const { messages } = useIntl();
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const deities = useAppSelector(selectCurrentTempleDeities)
  useEffect(() => {
    dispatch(deityListThunk({}))
  }, [dispatch])
  const handleFormChange = (requestType: ReceiptRequestTypes, values: ReceiptFilterFormType) => {
    changeFilter(requestType, formToFilterArgs(values))
  }
  const validationSchema = yup.object({
    fromDate: yup.date(),
    toDate: yup.date()
      .when('fromDate', (fromDate: any, schema: any) => {
        try {
          if (
            yup.date().isValidSync(fromDate)
          ) return schema.min(fromDate)
        } finally {
          return schema
        }
      }),
    fromBookingDate: yup.date(),
    toBookingDate: yup.date()
      .when('fromBookingDate', (fromBookingDate: any, schema: any) => {
        try {
          if (
            yup.date().isValidSync(fromBookingDate)
          ) return schema.min(fromBookingDate)
        } finally {
          return schema
        }
      }),
    deity: yup.number().positive(),
    category: yup.number().positive(),
    offering: yup.number().positive(),
    fromTime: yup.date(),
    toTime: yup.date()
  })
  return (
    <PaginatedToolbar titleId="booking.title" handleShowFilter={handleShowFilter} showFilters={showFilters}>
      <Formik
        initialValues={filterInitValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => handleFormChange('list', values)}
      >
        {({ values, setFieldValue, isValid, errors, resetForm }) => (
          <Form noValidate autoComplete="off">
            <Grid container direction="row" spacing={6} className={classes.formGridRoot}>
              <Grid item container md={4} xs={12} spacing={2}>
                <Grid item xs={12}>
                  <IntlMessages id="receipt.order.date.on" />
                </Grid>
                <Grid item sm={6} xs={12} className={classes.field}>
                  <KeyboardDatePicker
                    autoOk
                    size="small"
                    label={messages['common.from']}
                    format={defaultDateFormat}
                    value={values.fromBookingDate === '' ? null : values.fromBookingDate}
                    placeholder={defaultDateFormat}
                    name='fromBookingDate'
                    onChange={date => setFieldValue('fromBookingDate', date ?? '')}
                    className={classes.fieldRoot}
                    shouldDisableDate={(
                      day: MaterialUiPickersDate) =>
                      values.toBookingDate !== '' && day && day.toDateString() !== values.toBookingDate.toDateString() ?
                        day >= values.toBookingDate : false
                    }
                  />
                </Grid>
                <Grid item sm={6} xs={12} className={classes.field}>
                  <KeyboardDatePicker
                    autoOk
                    size="small"
                    label={messages['common.to']}
                    format={defaultDateFormat}
                    value={values.toBookingDate === '' ? null : values.toBookingDate}
                    placeholder={defaultDateFormat}
                    name='toBookingDate'
                    onChange={date => setFieldValue('toBookingDate', date ?? '')}
                    className={classes.fieldRoot}
                    shouldDisableDate={(
                      day: MaterialUiPickersDate) =>
                      values.fromBookingDate !== '' && day && day.toDateString() !== values.fromBookingDate.toDateString() ?
                        day <= values.fromBookingDate : false
                    }
                  />
                </Grid>
              </Grid>
              <Grid item container md={4} xs={12} spacing={2}>
                <Grid item xs={12}>
                  <IntlMessages id="receipt.booking.date" />
                </Grid>
                <Grid item sm={6} xs={12} className={classes.field}>
                  <KeyboardDatePicker
                    autoOk
                    size="small"
                    label={messages['common.from']}
                    format={defaultDateFormat}
                    value={values.fromDate === '' ? null : values.fromDate}
                    placeholder={defaultDateFormat}
                    name='fromDate'
                    onChange={date => setFieldValue('fromDate', date ?? '')}
                    className={classes.fieldRoot}
                    shouldDisableDate={(
                      day: MaterialUiPickersDate) =>
                      values.toDate !== '' && day && day.toDateString() !== values.toDate.toDateString() ?
                        day >= values.toDate : false
                    }
                  />
                </Grid>
                <Grid item sm={6} xs={12} className={classes.field}>
                  <KeyboardDatePicker
                    autoOk
                    size="small"
                    label={messages['common.to']}
                    format={defaultDateFormat}
                    value={values.toDate === '' ? null : values.toDate}
                    placeholder={defaultDateFormat}
                    name='toDate'
                    className={classes.fieldRoot}
                    onChange={date => {
                      setFieldValue('toDate', date ?? '')
                      // submitForm()
                    }}
                    shouldDisableDate={(
                      day: MaterialUiPickersDate) =>
                      values.fromDate !== '' && day && day.toDateString() !== values.fromDate.toDateString() ?
                        day <= values.fromDate : false
                    }
                  />
                </Grid>
              </Grid>
              <Grid item container md={4} xs={12} spacing={2}>
                <Grid item xs={12}>
                  <IntlMessages id="offering.time" />
                </Grid>
                <Grid item sm={6} xs={12} className={classes.field}>
                  <KeyboardTimePicker
                    size="small"
                    label={messages['common.from']}
                    value={values.fromTime === '' ? null : values.fromTime}
                    name='fromTime'
                    className={classes.fieldRoot}
                    mask="__:__ _M"
                    placeholder="hh:mm AM/PM"
                    onChange={date => setFieldValue('fromTime', date ?? '')}
                  />
                </Grid>
                <Grid item sm={6} xs={12} className={classes.field}>
                  <KeyboardTimePicker
                    size="small"
                    label={messages['common.to']}
                    value={values.toTime === '' ? null : values.toTime}
                    name='toTime'
                    className={classes.fieldRoot}
                    mask="__:__ _M"
                    placeholder="hh:mm AM/PM"
                    onChange={date => setFieldValue('toTime', date ?? '')}
                  />
                </Grid>
              </Grid>
              <Grid item container md={4} xs={12} spacing={2}>
                <Grid item className={classes.field}>
                  <FormikTextField
                    select
                    name='deity'
                    className={classes.fieldRoot}
                    size="small"
                    label={<IntlMessages id='deity' />}
                    margin="none"
                  >
                    <MenuItem value="">None</MenuItem>
                    {deities.map(deity => (
                      <MenuItem key={deity.id} value={deity.id}>
                        {deity.name}
                      </MenuItem>
                    ))}
                  </FormikTextField>
                </Grid>
              </Grid>
              <Grid item container md={4} xs={12} spacing={2}>
                <Grid item className={classes.field}>
                  <Grid item className={classes.field}>
                    <BookingsDeitySelect
                      className={classes.fieldRoot}
                      deityId={values.deity}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container md={4} xs={12} spacing={2}>
                <Grid item className={classes.field}>
                  <Grid item className={classes.field}>
                    <BookingsOfferingSelect
                      className={classes.fieldRoot}
                      deityId={values.deity}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container spacing={4} xs={12}>
                <Grid item>
                  <Button disabled={!isValid || receiptApi.status === apiStatus.LOADING} variant="contained" size="small" color="primary" type="submit">
                    <IntlMessages id="common.apply" />
                  </Button>
                </Grid>
                <Grid item>
                  <Button name="buttonPDF" disabled={!isValid || receiptApi.status === apiStatus.LOADING} variant="outlined" size="small" color="primary"
                    onClick={() => handleFormChange('pdf', values)}
                  >
                    <IntlMessages id="common.download.pdf" />
                  </Button>
                </Grid>
                <Grid item>
                  <Button name="buttonPriestPDF" disabled={!isValid || receiptApi.status === apiStatus.LOADING} variant="outlined" size="small" color="primary"
                      onClick={() => handleFormChange('priestPDF', values)}
                    >
                      <IntlMessages id="common.priestDownload" />
                  </Button>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => resetForm()} size="small">
                    <RefreshIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </PaginatedToolbar>
  );
};

export default React.memo(BookingsToolbar)
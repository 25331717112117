import axios, {AxiosRequestConfig} from 'axios'
import { baseUrl } from 'shared/constants/AppConst'

const defaultConfig: AxiosRequestConfig= {
    baseURL: baseUrl,
    headers: {
        'Content-Type': 'application/json',
        'X-Temple-ID': ''
    },
}

export const apiInstance = axios.create(defaultConfig)
export const uploadInstance = axios.create({baseURL: baseUrl})

export const deleteWithUrl = {
    delete: async(url: string) => await apiInstance.delete(url)
}
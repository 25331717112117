import { CommonTableHeadCell, CommonTableOrder } from 'components/Table/common/types';
import PaginatedTableHead from 'components/Table/PaginatedTable/PaginatedTableHead';
import React from 'react'
import { IDonationReceiptRow } from 'store/reducers/temple/donationReceiptSlice/types';


const headCells: CommonTableHeadCell<IDonationReceiptRow>[] = [
  { id: 'id', align: 'left', disablePadding: false, label: 'Receipt Id', hasSorting: true },
  { id: 'bookingDate', align: 'left', disablePadding: false, label: 'Date of Booking', hasSorting: true },
  { id: 'date', align: 'left', disablePadding: false, label: 'Date of Donation', hasSorting: true },
  { id: 'category', align: 'left', disablePadding: false, label: 'Category',  hasSorting: true },
  { id: 'donation', align: 'left', disablePadding: false, label: 'Donation', hasSorting: true },
  { id: 'amount', align: 'right', disablePadding: false, label: 'Amount', hasSorting: false },
];


interface DonationReceiptTableHeadProps {
  onRequestSort: (property: keyof IDonationReceiptRow) => void;
  order: CommonTableOrder;
  orderBy: keyof IDonationReceiptRow;
}

function DonationReceiptTableHead(props: DonationReceiptTableHeadProps) {
  const { order, orderBy, onRequestSort } = props;
  return (
   <PaginatedTableHead 
    headCells={headCells} 
    orderBy={orderBy} 
    order={order} 
    sortHandler={onRequestSort} 
    />
  );
}


export default DonationReceiptTableHead
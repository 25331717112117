
import { format as dateFormat} from 'date-fns'
import { apiDateFormat } from "shared/constants/AppConst";
import { apiDateToDate } from "utils/fns";
import { DonationReceiptFilterFormType } from "types/store/donationReceipt";
import { DonationReceiptFilterArgsType } from "store/reducers/temple/donationReceiptSlice/types";

export const donationFilterInitValues: DonationReceiptFilterFormType = {
  fromDate: '',
  toDate: '',
  fromBookingDate: '',
  toBookingDate: '',
  category: '',
  donation: '',
}

export const donationFilterArgsToForm = (filterArgs: DonationReceiptFilterArgsType) : DonationReceiptFilterFormType => {
  return {
    fromDate: filterArgs.dateAfter ? apiDateToDate(filterArgs.dateAfter) : '',
    toDate: filterArgs.dateBefore ? apiDateToDate(filterArgs.dateBefore) : '',
    fromBookingDate: filterArgs.bookingDateAfter ? apiDateToDate(filterArgs.bookingDateAfter) : '',
    toBookingDate: filterArgs.bookingDateBefore ? apiDateToDate(filterArgs.bookingDateBefore) : '',
    donation:  filterArgs.donation ?? '',
    category: filterArgs.category ?? '',
  }
}

export const donationFormToFilterArgs = (formState: DonationReceiptFilterFormType) : Partial<DonationReceiptFilterArgsType> => {
  let filterArgs : Partial<DonationReceiptFilterArgsType> = {}
  if (formState.fromDate)
    filterArgs.dateAfter = dateFormat(formState.fromDate, apiDateFormat)
  if (formState.toDate)
    filterArgs.dateBefore= dateFormat(formState.toDate, apiDateFormat)
  if (formState.fromBookingDate){
    formState.fromBookingDate.setHours(0, 0, 0, 0)
    filterArgs.bookingDateAfter = formState.fromBookingDate.toISOString()
  }
  if (formState.toBookingDate){
    formState.toBookingDate.setHours(23, 59, 59, 999)
    filterArgs.bookingDateBefore = formState.toBookingDate.toISOString()
  }
  if (formState.category)
    filterArgs.category = formState.category
  if (formState.donation)
    filterArgs.donation = formState.donation
  return filterArgs
}